@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 361px) {
  .hidden-xxs {
    display: none;
  }
  .refine-xxs {
    margin-left: 40px;
  }

  .card-money-exchange {
    margin-top: 24px !important;
  }
}
@media (max-width: 380px) and (min-width: 310px) {
  .detail-logo {
    max-width: 300px !important;
    margin-left: -10px;
  }
  .row > * {
    padding-right: 0px;
    padding-left: 0px;
  }

  .card-money-exchange {
    margin-top: 24px !important;
  }
}
@media (max-width: 767px) {
  .card-money-exchange {
    margin-top: 24px !important;
  }
  .hidden-xs {
    display: none;
  }
  .row {
    margin: 0px;
  }
  .category-select {
    padding: 4px;
    margin-left: 13px;
    width: 96%;
  }
  .location-select {
    padding: 4px;
    margin-left: 13px;
    width: 96%;
  }
  .navbar-toggler {
    border: 0px;
    color: #ffffff;
    float: right;
  }
  .showing-result {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    /* font-weight: 700 !important; */
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0px;
  }
  .detail-logo {
    margin-top: 50px;
  }
  #carouselExampleCaptions {
    height: 215px;
  }
  #carouselExampleInterval {
    height: auto;
  }
  .carousel-inner img {
    max-height: 215px;
  }
  .carousel-inner .card img {
    max-height: 370px;
  }
  .carousel-nav {
    position: relative;
    margin-top: 25px;
    background: #000000;
  }
  .carousel-nav img {
    align-items: center;
    padding: 8px;
    color: #344054;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
  .carousel-inner-height {
    height: 215px !important;
  }
  .detail-business-title {
    margin-top: 20px;
  }
  .popular-category-container,
  .popular-location-container {
    text-align: center;
  }
  .btn-search-list-danger {
    margin-bottom: 15px;
    width: 100%;
  }
  .footer-bottom {
    text-align: center;
    line-height: 30px;
    height: 100% !important;
    margin: 0px;
    padding: 0px !important;
    padding-top: 10px !important;
  }
  .footer-copy-right {
    margin: 5px;
    text-align: center !important;
  }
  .pagination {
    margin-left: 30%;
    zoom: 60%;
  }
  .paragraph-margin-sm {
    margin-bottom: 12px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .hidden-sm {
    display: none;
  }
  .show-sm {
    display: block;
  }
  .btn-copy-pink,
  .btn-copy-pink:hover {
    font-size: 14px !important;
  }
  .navbar-toggler {
    border: 0px;
    color: #ffffff;
    float: right;
  }
  .card-money-exchange {
    margin-top: 24px !important;
  }
}
@media (min-width: 992px) {
  .hidden-md {
    display: none;
  }
  .show-md {
    display: block;
  }
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 252, 252); /* semi-transparent background */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* adjust the z-index as needed */
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
body {
  min-height: 100%;
}
.no-result-found {
  margin-top: 20%;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  color: #101828;
}
.try-else {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
}
.nav-background {
  background-color: #0040c1;
  height: 80px;
}
.navbar-logo {
  margin-right: 30px;
}
.navbar-nav .nav-link {
  color: #f2f4f7;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
  margin-right: 8px;
}
.navbar-nav .nav-link.active {
  color: #ecd721;
}
.navbar-nav .nav-link:hover {
  color: #ecd721;
}
.login-signup {
  margin-left: 15px;
  /* margin-right:30px; */
  color: #f2f4f7;
  font-style: normal;
  font-weight: 600;

  line-height: 24px;
}
.login-signup-link {
  color: #f2f4f7;
  text-decoration: none;
}
.btn-nav-light,
.btn-nav-light:hover {
  color: #b61a3c;
  border: 1px solid #1570ef;
  border-radius: 8px;
  height: 40px;
  color: var(--gray-100, #f2f4f7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  background: var(--blue-600, #1570ef);
}
.custom-color {
  color: #f2f4f7;
}
.active-link {
  color: #b61a3c !important;
}
.footer-bottom {
  width: 100%;
  height: 100%;
  bottom: 0;
  margin-top: 25px;
  background: #1d2939;
  align-items: center;
  padding: 48px 0px;
}
.footer-brand {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 162px;
}
.footer-link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #eaecf0;
}
.footer-copy-right {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #98a2b3;
  margin-bottom: 2px;
}
.decorate-none {
  text-decoration: none;
}
.color-dark {
  color: #475467;
}
.featured-business-title {
  margin-top: 32px;
  margin-bottom: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
.inner-content {
  margin-left: 0px;
  margin-right: 0px;
}
.card-holder {
  padding-left: 8px;
  padding-right: 8px;
}
.card {
  margin-top: 5px;
  margin-bottom: 20px;
  border-color: #eaecf0;
  border-radius: 16px;
  padding: 20px;
}
.card:hover {
  /*transform: scale(1.003);*/
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.06);
}
.card-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.card-title {
  padding-top: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 8px;
}
.card-title > a {
  color: #101828;
}
.card-subtitle {
  margin-top: 0;
  margin-bottom: 12px;
  color: #363f72;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.card-views {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.card-btn-view-detail,
.card-btn-view-detail:hover {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 36px;
}
.dot-icon {
  margin-top: -2px;
}
.card-business {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #026aa2;
  background-color: #f0f9ff;
}
.card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  min-height: 72px;
}
.card-footer {
  border-color: #eaecf0;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 14px;
  padding-bottom: 0px;
}
.card-footer-content {
  margin-bottom: 0px;
}
.show-more {
  margin-top: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b61a3c;
}
.ad {
  margin-top: 26px;
  border-top: 1px solid #f5f5f8;
  border-bottom: 1px solid #f5f5f8;
}
.ad > div > img {
  margin: 0.5rem;
  max-width: 80%;
}
.custom-border {
  border: 1px solid #eaecf0;
  margin-top: 32px;
  margin-bottom: 0px;
}
.popular-category-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.popular-category-title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.popular-category {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.popular-location-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.popular-location-title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.category-link {
  color: #475467;
  margin-top: 6px;
  margin-bottom: 6px;
}
.category-count {
  color: #1e1f20;
  font-weight: 450;
}
.advertise-container {
  margin-top: 96px;
  margin-bottom: 96px;
}
.advertise-heading {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
}
.advertise-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-bottom: 35px;
}
.btn-advertise-light,
.btn-advertise-light:hover {
  width: 128px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  color: #344054;
}
.btn-advertise-danger,
.btn-advertise-danger:hover {
  width: 128px;
  height: 48px;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  color: #ffffff;
  margin-left: 12px;
}

/* Search Bar */

.hero--video {
  display: flex;
  align-items: center;
  min-height: 362px;
}

.hero img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 362px;
  margin: 0 auto;
  right: 0;
  left: 0;
  object-fit: cover;
}

.hero .search {
  position: relative;
  vertical-align: middle;
  z-index: 2;
}

.hero .search input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.btn-search-danger {
  color: white;
  width: 100px;
  height: 38px;
  margin-top: 5px;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.btn-search-list-danger {
  color: white;
  width: 100%;
  height: 38px;
  margin-top: 12px;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.css-13cymwt-control {
  border-radius: 8px !important;
}
.banner-text-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-bottom: 30px;
}
.banner-text-1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  margin-bottom: 16px;
}
.category-select {
  padding: 4px;
}
.location-select {
  padding: 4px;
}
/* Media Queries */

@media (max-width: 767.98px) {
  /* .hero .search input {
    background: rgba(0, 0, 0, 0.92);
    font-size: 20px;
        padding: 5px 5px 5px 10px;
        margin: 20px;
    height: 60px;
  } */

  .hero .search button {
    width: inherit;
    margin-left: 5px;
  }
}

@media (max-width: 991.98px) {
  .row {
    width: 100%;
  }
}

/*Search List Page*/
.bread-and-sort {
  margin-top: 28px;
  margin-bottom: 14px;
}
/*.sort-by-section{
    margin-left: 65% ;
}*/
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: #767f8d;
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABrSURBVHgBjY3BDYAgEASPCwXYhE8fdmQHSgVQAp0hAYOJElqwAT31p5AY9zmTzILxa2fn1EM2BGAV7Ye2IclcgnGLGqdIvyTLJUOUxEDg65AjFSkborxTxkX1DY2PQwGvcQDaiEi0Ta2f4gTrLUhqvp2lsQAAAABJRU5ErkJggg==);
}
.breadcrumb-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.breadcrumb-item > a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.breadcrumb-active {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #b61a3c;
}
.similar-location {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 15px;
  color: #b61a3c;
}
.sort-by {
  padding: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 1em;
  vertical-align: inherit;
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBfZCxDYAgFEQPE5zDEVzBTbQwsaS3sTG29Ba6gSMwgo7gCPYUCgjRIHgJkH95jxBIyfockMfMux0/cVySAlMKutamiMMUEBR0SU6gUgtEFSHJwXasiN4tKPSg5GLk7RaATU/cTb4k1enDuF/yxJPgwx/hLYXgaBo2ZLFfuwAxETfh/4I2oAAAAABJRU5ErkJggg==);
  border: 0;
}
.dropdown-sort {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.showing-result {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}
/* .category-list {
  margin-top: 16px;
} */
.category-list:hover {
  transform: none;
  box-shadow: none;
}
.category-list-item {
  color: #98a2b3;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.category-list-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.carousel {
  height: 500px;
}
/* .carousel-inner-height{
    height:500px;
    max-width: 800px;
}*/
.carousel-img-height {
  /* max-height:500px; */
  margin: 0 auto;
}
.detail-logo {
  max-width: 355px;
  max-height: 120px;
}
.detail-business-title {
  padding-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
}
.detail_info {
  padding-top: 15px;
}
.detail-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
  margin-top: 15px;
}
.detail-desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #475467;
}
.location-on-detail {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #363f72;
}
.detail-business-pill {
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #026aa2;
  background-color: #f0f9ff;
  width: auto;
}
.salary-pill {
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #070707;
  background-color: #ebebeb;
  width: auto;
  margin-left: 5px;
}
.for-adults {
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #b93815;
  background-color: #fff4ed;
  width: auto;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}
.detail-views {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.detail-links {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
}
.detail-links a {
  color: #475467 !important;
}
.detail-links a:hover {
  color: #b61a3c !important;
}
.btn-detail-danger,
.btn-detail-danger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  grid-gap: 8px;
  gap: 8px;

  height: 36px;
  background-color: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  color: #ffffff;
  margin-top: 24px;
  margin-bottom: 16px;
}
.detail-custom-border {
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.1;
}
.detail-ad {
  margin-bottom: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.btn-copy-pink,
.btn-copy-pink:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  grid-gap: 8px;
  gap: 8px;

  height: 36px;

  color: #b61a3c;
  background: #fce5ea;
  border: 1px solid #fce5ea;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
}
.detail-social-links {
  margin-left: 8px;
  margin-right: 8px;
}
.detail-card {
  min-height: 190px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-color: #eaecf0;
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
}
.detail-card:hover {
  transform: none;
  box-shadow: none;
}
.detail-card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 8px;
}
.detail-card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}
.btn-detail-light,
.btn-detail-light:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  grid-gap: 8px;
  gap: 8px;

  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.contact-container {
  margin-top: 90px;
  margin-bottom: 90px;
}
.contact-header {
  margin-top: 6px;
  margin-bottom: 90px;
}
.contact-heading-1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #b61a3c;
  margin-top: 6px;
  margin-bottom: 6px;
}
.contact-heading-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  margin-top: 6px;
  margin-bottom: 12px;
}
.contact-compliment {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-top: 12px;
  margin-bottom: 90px;
}
.input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.contact-check-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}
.contact-privacy-link {
  text-decoration: underline;
  color: #475467;
}
.btn-contact-danger,
.btn-contact-danger:hover {
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.pagination-container {
  margin: 100px auto;
  text-align: center;
}
.pagination {
  position: relative;
}
.pagination a {
  position: relative;
  display: inline-block;
  color: #2c3e50;
  border-radius: 25px;
  border: 1px solid #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 3px 12px;
}

.page-item .disabled {
  box-shadow: none;
  color: #fff;
  border-radius: 25px;
  background-color: #c6153b;
  border: 1px solid #c6153b;
}

.page-item {
  margin: 2.5px !important;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  border-radius: 25px;
  background-color: #c6153b;
  border: 1px solid #c6153b;
}
.page-link-arrow {
  border: none !important;
}
.page-link-arrow:before,
.page-link-arrow:hover {
  border: none;
}

.section-divider {
  border: 1px solid #eaecf0;
  margin-top: 8px;
  margin-bottom: 8px;
}
.radio-filter {
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.radio-filter .form-switch .form-check-input {
  height: 20px;
  width: 2.7em;
  box-shadow: none;
  margin-left: -1.5em;
}
.radio-filter .form-switch .form-check-input:checked {
  background-color: #b61a3c;
  border: #b61a3c;
}
.btn-small-danger,
.btn-small-danger:hover {
  z-index: 0 !important;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #ffffff;
}
.modal-filter-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.modal-filter {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.content-header {
  margin-top: 24px;
  margin-bottom: 24px;
}
.content {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: #475467;
  margin-left: 1%;
}
.content-key-point {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: #475467;
  margin-left: 5%;
}
.content-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
}
.content-sub-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
  margin-top: 15px;
}
.content-sub-heading-2 {
  font-weight: 500;
  font-size: 19px;
  line-height: 30px;
  color: #475467;
  margin-top: 10px;
}
.refine-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.btn-search-refine,
.btn-search-refine:hover {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 35px !important;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 48px;
  width: 90%;
}
.loader-in-component-body {
  margin-top: 30%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-control:focus {
  border-color: #ffffff;
  box-shadow: inset 0 0.1px 0.1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(189, 102, 121);
}
select:focus {
  border-color: #ffffff;
  box-shadow: inset 0 0.1px 0.1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(189, 102, 121);
}

.upcoming-events-title {
  margin-top: 32px;
  margin-bottom: 4px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #667085;
}
.upcoming-events-location {
  margin-top: 4px;
  margin-bottom: 28px;
}
.event-btn-change-city {
  display: flex;
  margin-top: 4px;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.event-tabs .nav-link {
  color: #667085;
  background-color: #ffffff;
  margin: 0.1rem;
}
.event-tabs .nav-link:active,
.event-tabs .nav-link:hover {
  color: #b61a3c;
  background-color: #fce5ea;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #b61a3c;
  background-color: #fce5ea;
}
.location-show {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.detail-event-title {
  padding-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #101828;
}
.event-tickets-container {
  margin-top: 20px;
}
.ticket-card {
  min-height: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  border-color: #eaecf0;
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
}
.dticket-card:hover {
  transform: none;
  box-shadow: none;
}
.event-ticket-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.btn-ticket-cart,
.btn-ticket-cart:hover {
  max-width: 44px;
  max-height: 40px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.event-ticket-left {
  margin-top: 14px;
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.event-ticket-price {
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.event-ticket-text-fade {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--gray-600, #475467);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.ticket-info-count {
  color: var(--gray-500, #667085);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.ticket-info-inclusive {
  color: var(--gray-500, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.ticket-info-grand-total {
  color: var(--gray-900, #101828);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.ticket-info-list {
  color: var(--gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 20px;
  margin-bottom: 8px;
}
.ticket-info-learn-more {
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.btn-ticket-reserve {
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.btn-ticket-reserve:hover {
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.btn-disabled-ticket-reserve {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.btn-disabled-ticket-reserve:hover {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.login-container {
  display: flex;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  grid-gap: 32px;
  gap: 32px;
  border-radius: 12px;
  background: var(--base-white, #fff);
}
.login-heading {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.remaining-time {
  color: var(--gray-700, #b61a3c);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.input-label {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.input-text-size {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.forgot-password {
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.btn-login {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-login:hover {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-login {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-login:hover {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-register {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-register:hover {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-register {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-register:hover {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.account {
  color: var(--gray-600, #475467);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.sign-up {
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.login-event-title {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.event-align-middle {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.ticket-waiting {
  color: var(--gray-500, #667085);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin-bottom: 0px;
}
.inclusive-fee {
  color: var(--gray-500, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
}
.not-reserved {
  color: var(--primary-500, #dd0c39);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.grand-total {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}
.register-heading {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.register-sub-heading {
  color: var(--gray-900, #101828);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.logged-in-user {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.logged-in-user > span {
  color: var(--primary-500, #dd0c39);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.field-required {
  color: var(--primary-500, #dd0c39);
}
.coupon-link {
  text-align: center;
}
.password-limit {
  color: var(--primary-500, #344054);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0px;
}
.enter-coupon {
  color: var(--primary-700, #b61a3c);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.btn-small-coupon,
.btn-small-coupon:hover {
  z-index: 0 !important;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-back,
.btn-back:hover {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-blue,
.btn-blue:hover {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: #0040c1;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.discount-applied-success {
  color: var(--success-900, #054f31);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.ticket-card-disable {
  opacity: 0.5;
  pointer-events: none;
}
.card-money-exchange {
  min-height: auto;
  margin-top: 32px;
  margin-bottom: 0px;
}
.card-body-money-exchange {
  padding-bottom: 0px;
}
.live-exchange-rate-text {
  margin-bottom: 0px;
  color: var(--gray-600, #475467);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.live-exchange-rate {
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--gray-600, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.btn-send-money,
.btn-send-money:hover,
.btn-send-money:focus {
  margin-top: 16px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #475467;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.remove-body-padding-bottom {
  padding-bottom: 0px;
}
.card-btn-buy-ticket,
.card-btn-buy-ticket:hover,
.card-btn-buy-ticket:focus {
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--fuchsia-600, #ba24d5);
  background: var(--fuchsia-700, #9f1ab1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.ticket-booked-coontainer {
  margin-top: 72px;
  margin-bottom: 28px;
}
.ticket-booked-title {
  color: var(--gray-900, #101828);
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin-top: 24px;
}
.ticket-booked-event-title {
  color: var(--gray-900, #101828);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ticket-detail-area {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mailed-to {
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.mail-to-text {
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.qrcode-container {
  margin-top: 24px;
  margin-bottom: 24px;
}
.btn-save-ticket,
.btn-save-ticket:hover,
.btn-save-ticket:focus {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-view-tickets,
.btn-view-tickets:hover,
.btn-view-tickets:focus {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

@font-face {
    font-family: 'Inter';
    src: url(/static/media/inter.0c819cf9.ttf) format('truetype');
}

body {
    font-family: 'Inter' ;
}
a{
    color:#b61a3c;
}

@font-face {
    font-family: 'Poppins';
    src: url(/static/media/Poppins-Regular.cd6b896a.ttf) format('truetype');
}
a {
  text-decoration: none;
}
.body-no-scroll {
  overflow: hidden;
}
.nav2-top {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 101;
  padding: 10px 20px;
  width: 100%;
  height: 50px;

}
.nav2-top .hamburger {
  margin-left: auto;
  color: #fff;
  cursor: pointer;
}

.nav2-drill {
  margin-top: 50px;
  transform: translateX(100%);
}

.nav2-is-toggled .nav2-drill {
  transform: translateX(0);
}
.nav2-is-toggled::after {
  opacity: 1;
  visibility: visible;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none
}
.nav2-drill {
  justify-content: center;

  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(235, 235, 235, 0.9);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: 0.45s;
  margin-top: 80px;
}
.nav2-items {
  flex: 0 0 95%;
  list-style: none;
  margin-left: 5%; 
  padding-left: 0px;
  background-color: #ffffff;
}
.nav2-item {
  border-bottom: solid 1px #ececec;
  box-shadow: none;
}
.nav2-link {
  display: block;
  padding: 1.2em 2em;
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 700;
  list-style: none;
  text-decoration: none;
  color: #253D4E;
}
.nav2-link:hover {
background-color: #B61A3C;
color: #f2f4f7;
}
.nav2-link.active {
  color: #f2f4f7;
  background-color: #c6153b;
}
.nav2-expand-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  background-color: #0040C1  ;
  transition: 0.3s;
  visibility: hidden;
}
.nav2-expand-content .nav2-item:not(:last-child) {
  border-bottom: solid 1px #0040C1;
}
.nav2-expand-content .nav2-link {
  background-color: #0040C1;
}
.nav2-expand-content .nav2-back-link {
  display: flex;
  align-items: center;
  background-color: #0040C1 !important;
  color: #fff;
}
.nav2-expand-content .nav2-back-link::before {
  content: "chevron_left";
  margin-right: 0.5em;
  font-family: "Material Icons";
}
.nav2-expand-link {
  display: flex;
  justify-content: space-between;
}
.nav2-expand-link::after {
  content: "chevron_right";
  flex: 0 1 auto;
  font-family: "Material Icons";
}
.nav2-expand.active > .nav2-expand-content {
  transform: translateX(0);
  visibility: visible;
}
.nav2-expand .nav2-expand-content {
  background-color: #daf9f4;
}
.nav2-expand .nav2-expand-content .nav2-link {
  background-color: #daf9f4;
}
.nav2-expand .nav2-expand-content .nav2-expand-content {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-link {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-link {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-expand-content {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-link {
  background-color: #0040C1;
}
