@media (max-width: 361px) {
  .hidden-xxs {
    display: none;
  }
  .refine-xxs {
    margin-left: 40px;
  }

  .card-money-exchange {
    margin-top: 24px !important;
  }
}
@media (max-width: 380px) and (min-width: 310px) {
  .detail-logo {
    max-width: 300px !important;
    margin-left: -10px;
  }
  .row > * {
    padding-right: 0px;
    padding-left: 0px;
  }

  .card-money-exchange {
    margin-top: 24px !important;
  }
}
@media (max-width: 767px) {
  .card-money-exchange {
    margin-top: 24px !important;
  }
  .hidden-xs {
    display: none;
  }
  .row {
    margin: 0px;
  }
  .category-select {
    padding: 4px;
    margin-left: 13px;
    width: 96%;
  }
  .location-select {
    padding: 4px;
    margin-left: 13px;
    width: 96%;
  }
  .navbar-toggler {
    border: 0px;
    color: #ffffff;
    float: right;
  }
  .showing-result {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    /* font-weight: 700 !important; */
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0px;
  }
  .detail-logo {
    margin-top: 50px;
  }
  #carouselExampleCaptions {
    height: 215px;
  }
  #carouselExampleInterval {
    height: auto;
  }
  .carousel-inner img {
    max-height: 215px;
  }
  .carousel-inner .card img {
    max-height: 370px;
  }
  .carousel-nav {
    position: relative;
    margin-top: 25px;
    background: #000000;
  }
  .carousel-nav img {
    align-items: center;
    padding: 8px;
    color: #344054;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
  }
  .carousel-inner-height {
    height: 215px !important;
  }
  .detail-business-title {
    margin-top: 20px;
  }
  .popular-category-container,
  .popular-location-container {
    text-align: center;
  }
  .btn-search-list-danger {
    margin-bottom: 15px;
    width: 100%;
  }
  .footer-bottom {
    text-align: center;
    line-height: 30px;
    height: 100% !important;
    margin: 0px;
    padding: 0px !important;
    padding-top: 10px !important;
  }
  .footer-copy-right {
    margin: 5px;
    text-align: center !important;
  }
  .pagination {
    margin-left: 30%;
    zoom: 60%;
  }
  .paragraph-margin-sm {
    margin-bottom: 12px !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .hidden-sm {
    display: none;
  }
  .show-sm {
    display: block;
  }
  .btn-copy-pink,
  .btn-copy-pink:hover {
    font-size: 14px !important;
  }
  .navbar-toggler {
    border: 0px;
    color: #ffffff;
    float: right;
  }
  .card-money-exchange {
    margin-top: 24px !important;
  }
}
@media (min-width: 992px) {
  .hidden-md {
    display: none;
  }
  .show-md {
    display: block;
  }
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 252, 252); /* semi-transparent background */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* adjust the z-index as needed */
}
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
body {
  min-height: 100%;
}
.no-result-found {
  margin-top: 20%;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  color: #101828;
}
.try-else {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
}
.nav-background {
  background-color: #0040c1;
  height: 80px;
}
.navbar-logo {
  margin-right: 30px;
}
.navbar-nav .nav-link {
  color: #f2f4f7;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
  margin-right: 8px;
}
.navbar-nav .nav-link.active {
  color: #ecd721;
}
.navbar-nav .nav-link:hover {
  color: #ecd721;
}
.login-signup {
  margin-left: 15px;
  /* margin-right:30px; */
  color: #f2f4f7;
  font-style: normal;
  font-weight: 600;

  line-height: 24px;
}
.login-signup-link {
  color: #f2f4f7;
  text-decoration: none;
}
.btn-nav-light,
.btn-nav-light:hover {
  color: #b61a3c;
  border: 1px solid #1570ef;
  border-radius: 8px;
  height: 40px;
  color: var(--gray-100, #f2f4f7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  background: var(--blue-600, #1570ef);
}
.custom-color {
  color: #f2f4f7;
}
.active-link {
  color: #b61a3c !important;
}
.footer-bottom {
  width: 100%;
  height: 100%;
  bottom: 0;
  margin-top: 25px;
  background: #1d2939;
  align-items: center;
  padding: 48px 0px;
}
.footer-brand {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 162px;
}
.footer-link {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #eaecf0;
}
.footer-copy-right {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #98a2b3;
  margin-bottom: 2px;
}
.decorate-none {
  text-decoration: none;
}
.color-dark {
  color: #475467;
}
.featured-business-title {
  margin-top: 32px;
  margin-bottom: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
.inner-content {
  margin-left: 0px;
  margin-right: 0px;
}
.card-holder {
  padding-left: 8px;
  padding-right: 8px;
}
.card {
  margin-top: 5px;
  margin-bottom: 20px;
  border-color: #eaecf0;
  border-radius: 16px;
  padding: 20px;
}
.card:hover {
  /*transform: scale(1.003);*/
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.06);
}
.card-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.card-title {
  padding-top: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 8px;
}
.card-title > a {
  color: #101828;
}
.card-subtitle {
  margin-top: 0;
  margin-bottom: 12px;
  color: #363f72;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.card-views {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.card-btn-view-detail,
.card-btn-view-detail:hover {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 36px;
}
.dot-icon {
  margin-top: -2px;
}
.card-business {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #026aa2;
  background-color: #f0f9ff;
}
.card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  min-height: 72px;
}
.card-footer {
  border-color: #eaecf0;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 14px;
  padding-bottom: 0px;
}
.card-footer-content {
  margin-bottom: 0px;
}
.show-more {
  margin-top: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #b61a3c;
}
.ad {
  margin-top: 26px;
  border-top: 1px solid #f5f5f8;
  border-bottom: 1px solid #f5f5f8;
}
.ad > div > img {
  margin: 0.5rem;
  max-width: 80%;
}
.custom-border {
  border: 1px solid #eaecf0;
  margin-top: 32px;
  margin-bottom: 0px;
}
.popular-category-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.popular-category-title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.popular-category {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.popular-location-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
.popular-location-title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.category-link {
  color: #475467;
  margin-top: 6px;
  margin-bottom: 6px;
}
.category-count {
  color: #1e1f20;
  font-weight: 450;
}
.advertise-container {
  margin-top: 96px;
  margin-bottom: 96px;
}
.advertise-heading {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
}
.advertise-desc {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-bottom: 35px;
}
.btn-advertise-light,
.btn-advertise-light:hover {
  width: 128px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  color: #344054;
}
.btn-advertise-danger,
.btn-advertise-danger:hover {
  width: 128px;
  height: 48px;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  color: #ffffff;
  margin-left: 12px;
}

/* Search Bar */

.hero--video {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 362px;
}

.hero img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 362px;
  margin: 0 auto;
  right: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.hero .search {
  position: relative;
  vertical-align: middle;
  z-index: 2;
}

.hero .search input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.btn-search-danger {
  color: white;
  width: 100px;
  height: 38px;
  margin-top: 5px;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.btn-search-list-danger {
  color: white;
  width: 100%;
  height: 38px;
  margin-top: 12px;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.css-13cymwt-control {
  border-radius: 8px !important;
}
.banner-text-2 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-bottom: 30px;
}
.banner-text-1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  margin-bottom: 16px;
}
.category-select {
  padding: 4px;
}
.location-select {
  padding: 4px;
}
/* Media Queries */

@media (max-width: 767.98px) {
  /* .hero .search input {
    background: rgba(0, 0, 0, 0.92);
    font-size: 20px;
        padding: 5px 5px 5px 10px;
        margin: 20px;
    height: 60px;
  } */

  .hero .search button {
    width: inherit;
    margin-left: 5px;
  }
}

@media (max-width: 991.98px) {
  .row {
    width: 100%;
  }
}

/*Search List Page*/
.bread-and-sort {
  margin-top: 28px;
  margin-bottom: 14px;
}
/*.sort-by-section{
    margin-left: 65% ;
}*/
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: #767f8d;
  content: url("../gallery/icons/rightArrow.png");
}
.breadcrumb-item {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.breadcrumb-item > a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.breadcrumb-active {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #b61a3c;
}
.similar-location {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 15px;
  color: #b61a3c;
}
.sort-by {
  padding: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 1em;
  vertical-align: inherit;
  content: url("../gallery/icons/downArrow.png");
  border: 0;
}
.dropdown-sort {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.showing-result {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  margin-left: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}
/* .category-list {
  margin-top: 16px;
} */
.category-list:hover {
  transform: none;
  box-shadow: none;
}
.category-list-item {
  color: #98a2b3;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.category-list-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.carousel {
  height: 500px;
}
/* .carousel-inner-height{
    height:500px;
    max-width: 800px;
}*/
.carousel-img-height {
  /* max-height:500px; */
  margin: 0 auto;
}
.detail-logo {
  max-width: 355px;
  max-height: 120px;
}
.detail-business-title {
  padding-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
}
.detail_info {
  padding-top: 15px;
}
.detail-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
  margin-top: 15px;
}
.detail-desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #475467;
}
.location-on-detail {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #363f72;
}
.detail-business-pill {
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #026aa2;
  background-color: #f0f9ff;
  width: auto;
}
.salary-pill {
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #070707;
  background-color: #ebebeb;
  width: auto;
  margin-left: 5px;
}
.for-adults {
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 11px;
  padding-right: 12px;
  color: #b93815;
  background-color: #fff4ed;
  width: auto;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}
.detail-views {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.detail-links {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #475467;
}
.detail-links a {
  color: #475467 !important;
}
.detail-links a:hover {
  color: #b61a3c !important;
}
.btn-detail-danger,
.btn-detail-danger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;

  height: 36px;
  background-color: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  color: #ffffff;
  margin-top: 24px;
  margin-bottom: 16px;
}
.detail-custom-border {
  margin-top: 5px;
  margin-bottom: 5px;
  opacity: 0.1;
}
.detail-ad {
  margin-bottom: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.btn-copy-pink,
.btn-copy-pink:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;

  height: 36px;

  color: #b61a3c;
  background: #fce5ea;
  border: 1px solid #fce5ea;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 22px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
}
.detail-social-links {
  margin-left: 8px;
  margin-right: 8px;
}
.detail-card {
  min-height: 190px;
  margin-top: 5px;
  margin-bottom: 20px;
  border-color: #eaecf0;
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
}
.detail-card:hover {
  transform: none;
  box-shadow: none;
}
.detail-card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 8px;
}
.detail-card-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}
.btn-detail-light,
.btn-detail-light:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;

  height: 40px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.contact-container {
  margin-top: 90px;
  margin-bottom: 90px;
}
.contact-header {
  margin-top: 6px;
  margin-bottom: 90px;
}
.contact-heading-1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #b61a3c;
  margin-top: 6px;
  margin-bottom: 6px;
}
.contact-heading-2 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  margin-top: 6px;
  margin-bottom: 12px;
}
.contact-compliment {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #475467;
  margin-top: 12px;
  margin-bottom: 90px;
}
.input-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.contact-check-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}
.contact-privacy-link {
  text-decoration: underline;
  color: #475467;
}
.btn-contact-danger,
.btn-contact-danger:hover {
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 48px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.pagination-container {
  margin: 100px auto;
  text-align: center;
}
.pagination {
  position: relative;
}
.pagination a {
  position: relative;
  display: inline-block;
  color: #2c3e50;
  border-radius: 25px;
  border: 1px solid #ffffff;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 3px 12px;
}

.page-item .disabled {
  box-shadow: none;
  color: #fff;
  border-radius: 25px;
  background-color: #c6153b;
  border: 1px solid #c6153b;
}

.page-item {
  margin: 2.5px !important;
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  border-radius: 25px;
  background-color: #c6153b;
  border: 1px solid #c6153b;
}
.page-link-arrow {
  border: none !important;
}
.page-link-arrow:before,
.page-link-arrow:hover {
  border: none;
}

.section-divider {
  border: 1px solid #eaecf0;
  margin-top: 8px;
  margin-bottom: 8px;
}
.radio-filter {
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.radio-filter .form-switch .form-check-input {
  height: 20px;
  width: 2.7em;
  box-shadow: none;
  margin-left: -1.5em;
}
.radio-filter .form-switch .form-check-input:checked {
  background-color: #b61a3c;
  border: #b61a3c;
}
.btn-small-danger,
.btn-small-danger:hover {
  z-index: 0 !important;
  background: #c6153b;
  border: 1px solid #c6153b;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: #ffffff;
}
.modal-filter-label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}
.modal-filter {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
}
.modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
}
.content-header {
  margin-top: 24px;
  margin-bottom: 24px;
}
.content {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: #475467;
  margin-left: 1%;
}
.content-key-point {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
  color: #475467;
  margin-left: 5%;
}
.content-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
}
.content-sub-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
  margin-top: 15px;
}
.content-sub-heading-2 {
  font-weight: 500;
  font-size: 19px;
  line-height: 30px;
  color: #475467;
  margin-top: 10px;
}
.refine-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.btn-search-refine,
.btn-search-refine:hover {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 35px !important;
  color: #344054;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 48px;
  width: 90%;
}
.loader-in-component-body {
  margin-top: 30%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-control:focus {
  border-color: #ffffff;
  box-shadow: inset 0 0.1px 0.1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(189, 102, 121);
}
select:focus {
  border-color: #ffffff;
  box-shadow: inset 0 0.1px 0.1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(189, 102, 121);
}
