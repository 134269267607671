@font-face {
    font-family: 'Inter';
    src: url('font/inter.ttf') format('truetype');
}

body {
    font-family: 'Inter' ;
}
a{
    color:#b61a3c;
}

@font-face {
    font-family: 'Poppins';
    src: url('font/Poppins-Regular.ttf') format('truetype');
}