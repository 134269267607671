.upcoming-events-title {
  margin-top: 32px;
  margin-bottom: 4px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: #667085;
}
.upcoming-events-location {
  margin-top: 4px;
  margin-bottom: 28px;
}
.event-btn-change-city {
  display: flex;
  margin-top: 4px;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.event-tabs .nav-link {
  color: #667085;
  background-color: #ffffff;
  margin: 0.1rem;
}
.event-tabs .nav-link:active,
.event-tabs .nav-link:hover {
  color: #b61a3c;
  background-color: #fce5ea;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #b61a3c;
  background-color: #fce5ea;
}
.location-show {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.detail-event-title {
  padding-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #101828;
}
.event-tickets-container {
  margin-top: 20px;
}
.ticket-card {
  min-height: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  border-color: #eaecf0;
  border-radius: 16px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
}
.dticket-card:hover {
  transform: none;
  box-shadow: none;
}
.event-ticket-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.btn-ticket-cart,
.btn-ticket-cart:hover {
  max-width: 44px;
  max-height: 40px;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.event-ticket-left {
  margin-top: 14px;
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.event-ticket-price {
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.event-ticket-text-fade {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--gray-600, #475467);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.ticket-info-count {
  color: var(--gray-500, #667085);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.ticket-info-inclusive {
  color: var(--gray-500, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
.ticket-info-grand-total {
  color: var(--gray-900, #101828);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.ticket-info-list {
  color: var(--gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 20px;
  margin-bottom: 8px;
}
.ticket-info-learn-more {
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.btn-ticket-reserve {
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.btn-ticket-reserve:hover {
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.btn-disabled-ticket-reserve {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.btn-disabled-ticket-reserve:hover {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.login-container {
  display: flex;
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
  background: var(--base-white, #fff);
}
.login-heading {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.remaining-time {
  color: var(--gray-700, #b61a3c);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.input-label {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.input-text-size {
  color: var(--gray-700, #344054);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.forgot-password {
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.btn-login {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-login:hover {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-login {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-login:hover {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-register {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-register:hover {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-register {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-disable-register:hover {
  pointer-events: none;
  opacity: 0.5;
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.account {
  color: var(--gray-600, #475467);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.sign-up {
  color: var(--primary-700, #b61a3c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}
.login-event-title {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.event-align-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ticket-waiting {
  color: var(--gray-500, #667085);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin-bottom: 0px;
}
.inclusive-fee {
  color: var(--gray-500, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
}
.not-reserved {
  color: var(--primary-500, #dd0c39);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.grand-total {
  color: var(--gray-900, #101828);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
}
.register-heading {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.register-sub-heading {
  color: var(--gray-900, #101828);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}
.logged-in-user {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.logged-in-user > span {
  color: var(--primary-500, #dd0c39);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.field-required {
  color: var(--primary-500, #dd0c39);
}
.coupon-link {
  text-align: center;
}
.password-limit {
  color: var(--primary-500, #344054);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0px;
}
.enter-coupon {
  color: var(--primary-700, #b61a3c);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.btn-small-coupon,
.btn-small-coupon:hover {
  z-index: 0 !important;
  border-radius: 0px 8px 8px 0px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-back,
.btn-back:hover {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-blue,
.btn-blue:hover {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: #0040c1;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.discount-applied-success {
  color: var(--success-900, #054f31);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.ticket-card-disable {
  opacity: 0.5;
  pointer-events: none;
}
.card-money-exchange {
  min-height: auto;
  margin-top: 32px;
  margin-bottom: 0px;
}
.card-body-money-exchange {
  padding-bottom: 0px;
}
.live-exchange-rate-text {
  margin-bottom: 0px;
  color: var(--gray-600, #475467);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.live-exchange-rate {
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--gray-600, #667085);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.btn-send-money,
.btn-send-money:hover,
.btn-send-money:focus {
  margin-top: 16px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #475467;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.remove-body-padding-bottom {
  padding-bottom: 0px;
}
.card-btn-buy-ticket,
.card-btn-buy-ticket:hover,
.card-btn-buy-ticket:focus {
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--fuchsia-600, #ba24d5);
  background: var(--fuchsia-700, #9f1ab1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.ticket-booked-coontainer {
  margin-top: 72px;
  margin-bottom: 28px;
}
.ticket-booked-title {
  color: var(--gray-900, #101828);
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin-top: 24px;
}
.ticket-booked-event-title {
  color: var(--gray-900, #101828);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ticket-detail-area {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mailed-to {
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.mail-to-text {
  color: var(--gray-900, #101828);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.qrcode-container {
  margin-top: 24px;
  margin-bottom: 24px;
}
.btn-save-ticket,
.btn-save-ticket:hover,
.btn-save-ticket:focus {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--gray-700, #344054);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.btn-view-tickets,
.btn-view-tickets:hover,
.btn-view-tickets:focus {
  width: 100%;
  display: flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--primary-600, #c6153b);
  background: var(--primary-600, #c6153b);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--base-white, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
