@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
a {
  text-decoration: none;
}
.body-no-scroll {
  overflow: hidden;
}
.nav2-top {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 101;
  padding: 10px 20px;
  width: 100%;
  height: 50px;

}
.nav2-top .hamburger {
  margin-left: auto;
  color: #fff;
  cursor: pointer;
}

.nav2-drill {
  margin-top: 50px;
  transform: translateX(100%);
}

.nav2-is-toggled .nav2-drill {
  transform: translateX(0);
}
.nav2-is-toggled::after {
  opacity: 1;
  visibility: visible;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none
}
.nav2-drill {
  justify-content: center;

  display: flex;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(235, 235, 235, 0.9);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transition: 0.45s;
  margin-top: 80px;
}
.nav2-items {
  flex: 0 0 95%;
  list-style: none;
  margin-left: 5%; 
  padding-left: 0px;
  background-color: #ffffff;
}
.nav2-item {
  border-bottom: solid 1px #ececec;
  box-shadow: none;
}
.nav2-link {
  display: block;
  padding: 1.2em 2em;
  background-color: #ffffff;
  font-size: 1rem;
  line-height: 1.5em;
  font-weight: 700;
  list-style: none;
  text-decoration: none;
  color: #253D4E;
}
.nav2-link:hover {
background-color: #B61A3C;
color: #f2f4f7;
}
.nav2-link.active {
  color: #f2f4f7;
  background-color: #c6153b;
}
.nav2-expand-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  background-color: #0040C1  ;
  transition: 0.3s;
  visibility: hidden;
}
.nav2-expand-content .nav2-item:not(:last-child) {
  border-bottom: solid 1px #0040C1;
}
.nav2-expand-content .nav2-link {
  background-color: #0040C1;
}
.nav2-expand-content .nav2-back-link {
  display: flex;
  align-items: center;
  background-color: #0040C1 !important;
  color: #fff;
}
.nav2-expand-content .nav2-back-link::before {
  content: "chevron_left";
  margin-right: 0.5em;
  font-family: "Material Icons";
}
.nav2-expand-link {
  display: flex;
  justify-content: space-between;
}
.nav2-expand-link::after {
  content: "chevron_right";
  flex: 0 1 auto;
  font-family: "Material Icons";
}
.nav2-expand.active > .nav2-expand-content {
  transform: translateX(0);
  visibility: visible;
}
.nav2-expand .nav2-expand-content {
  background-color: #daf9f4;
}
.nav2-expand .nav2-expand-content .nav2-link {
  background-color: #daf9f4;
}
.nav2-expand .nav2-expand-content .nav2-expand-content {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-link {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-link {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-expand-content {
  background-color: #0040C1;
}
.nav2-expand .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-expand-content .nav2-link {
  background-color: #0040C1;
}